import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import Splash from '@/layouts/Splash'
import AuthLayout from 'bh-mod/layouts/Auth'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					path: '/',
					redirect: '/showunits',
					meta: {
						title: 'Condo Units',
						sidebar: true,
						dataRequired: true
					},
					component: () => import('./views/app/display'),
				},
				//add new unit
				{
					path: '/unitdetails',
					meta: {
						title: 'Unit Details',
						sidebar: true,
						dataRequired: true
					},
					component: () => import('./views/app/unit'),
				},
				{
					path: '/unitdetails/:id',
					meta: {
						title: 'Edit Unit Details',
						sidebar: true,
						dataRequired: true,
					},
					component: () => import('./views/app/unit'),
				},
				//show units
				{
					path: '/showunits',
					meta: {
						title: 'Display Units',
						sidebar: true,
						dataRequired: true,
						showUnits: true
					},
					component: () => import('./views/app/display'),
				},
				{
					path: '/settings',
					meta: {
						title: 'Settings',
						sidebar: true,
						dataRequired: true,
					},
					component: () => import('./views/settings'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [
				{
					path: '/setup',
					meta: {
						title: 'Setup Condo Units',
						splashScreen: true
					},
					component: () => import('./views/setup'),

				}
			]

		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
})

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'condounits')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (splashScreen && (store.state.condoUnits.allSettings.userApp.seenIntro && (Object.keys(store.state.condoUnits.units).length || Object.keys(store.state.condoUnits.floors).length) && !to.query.hasOwnProperty('dev'))) {
			return next('/')
		}
		if (dataRequired && (!store.state.condoUnits.allSettings.userApp.seenIntro && !Object.keys(store.state.condoUnits.floors).length) && !store.state.condoUnits.getStarted) {
			return next('/setup')
		}
	}
	store.commit('PAGE_BUTTONS', [])
	next()
})


export default router
