<template>
  <div id="app">
	<BHLoading :show="$store.state.loading" />
    <MediaSelector />
    <localization></localization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import BHLoading from 'bh-mod/components/common/Loading'

export default {
  name: 'app',
  components: { Localization,MediaSelector,BHLoading },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
}
</script>
<style>
  #app{
    min-height: 100vh;
  }
</style>
