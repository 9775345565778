<template>
  <div>
    <div class="top-bar-1 pb-3" style="align-items:center">
      <SubBarGlobal>
        <PageButtons />
      </SubBarGlobal>
      <div v-if="$route.meta.title == 'Display Units' " class="ml-auto d-none d-sm-flex top-bar-2">
        <a-auto-complete
          size="large"
          style="width: 300px"
          placeholder="Search for a unit..."
          :dropdown-match-select-width="false"
          :dropdown-style="{ width: '300px' }"
          option-label-prop="value"
          @search="handleSearch"
          class="mr-4"
        >
          <template slot="dataSource">
            <!-- <a-select-opt-group> -->
              <a-select-option @click="optionClicked(unit.id)" v-for="(unit, unitI) in searchResult" :key="unitI" :value="unit.name" >
                <div class="dF aC">
                  <span class="mr-2"><img style="width:30px; height:30px; object-fit:cover;" :src="`${unit.image}`" /></span>
                  <div class="f1">
                    <div><strong>{{`Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} ${unit.legalLevel ? ' - ' + unit.legalLevel : ''} ${unit.status == 'draft' ? '(Draft)' : ''}`}}</strong></div>
                    <small class="text-sm">{{unit.packages[0].beds || 0}} Bed(s) | {{unit.packages[0].baths || 0}} Bath(s) | {{unit.packages[0].parking || 0}} Parking Spots(s)</small>
                  </div>
                </div>
              </a-select-option>
            <!-- </a-select-opt-group> -->
          </template>
          <a-input>
            <a-icon slot="suffix" type="search"  />
          </a-input>
        </a-auto-complete>
        <a-button @click="addFloor" class="mr-3 addFloor-button" size="large"><a-icon type="plus" />ADD NEW FLOOR</a-button>
        <a-button @click="addUnit" type="primary" size="large"><a-icon type="plus"/>ADD NEW UNIT</a-button>
      </div>
    </div>
    <a-modal :centered="true" :visible="visible" :title="null" :footer="null" @cancel="visible =! visible">
      <h5>Add New Floor</h5>
      <p>Do you want to add floor {{floors.length+1}}?</p>
      <a-input v-model="newFloor" placeholder="Floor Name"/>
      <div style="text-align:end">
        <a-button size="large" class="mt-5 cancel-button" @click="visible =! visible">CANCEL</a-button>
        <a-button size="large" type="primary" class="mt-5 ml-3" @click="confirm">CONFIRM</a-button>
      </div>


    </a-modal>
  </div>


</template>
<script>
import SubBarGlobal from 'bh-mod/components/layout/SubBarGlobal'
import PageButtons from 'bh-mod/components/layout/SubBarGlobal/PageButtons'


export default {
    components: { SubBarGlobal, PageButtons },
    data() {
        return {
            visible: false,
            newFloor: '',
            searchResult: []
        }
    },
    watch: {
        searchResult: {
            async handler(val) {
                if (val.length) {
                    let id = val[0].id
                    if (this.$store.state.condoUnits.display == 'block') {
                        await document.querySelectorAll('.select-block').forEach(x => x.classList.remove('select-block'))
                        if (document.querySelector(`[card-unit-value="${id}"]`)) {
                            document.querySelector(`[card-unit-value="${id}"]`).classList.add('select-block')
                        }
                    } else if (this.$store.state.condoUnits.display == 'list') {
                        await document.querySelectorAll('.select-list-table').forEach(x => x.classList.remove('select-list-table'))
                        if (document.querySelector(`[data-row-key="${id}"]`)) {
                            document.querySelector(`[data-row-key="${id}"]`).classList.add('select-list-table')
                        }
                    }
                }
            }
        }
    },
    computed: {
        condo() {
            return this.$store.state.condoUnits.condo
        },
        instance() {
            return this.$store.state.instance
        },
        floors() {
            return this.$store.state.condoUnits.floors
        },
        units() {
            return this.$store.state.condoUnits.units
        }
    },
    methods: {
        optionClicked(id) {
            this.$router.push(`/unitdetails/${id}`)
        },
        handleSearch(input) {
            if (input && input.length > 2) {
                const search = input.toLowerCase()
                this.searchResult = Object.values(this.units).filter(({ unitCode, unitNumber, name, suffix, legalLevel }) => {
                    return unitCode?.toLowerCase().includes(search) || unitNumber?.toLowerCase().includes(search) || name?.toLowerCase().includes(search) || suffix?.toLowerCase().includes(search) || legalLevel?.toLowerCase().includes(search)
                })
            } else {
                return this.searchResult = []
            }
        },
        addUnit() {
            this.$router.push('/unitdetails')
        },
        addFloor() {
            this.visible = true
        },
        addModel() {
            this.$store.commit('SHOW_ADD_MODEL')
        },
        confirm() {

            this.$api.post(`/condos/${this.instance.id}/${this.condo.id}/floors`, { name: `${this.newFloor}` })
                .then(({ data }) => {
                    this.$store.commit('ADD_FLOOR', data.floors)
                }).catch(err => {
                    if (!err || !err.response || !err.response.status || err.response.status !== 400) {
                        this.$message.error(this.$err(err))
                    }
                })

            this.visible = false
            this.newFloor = ''
        },

    }
}
</script>
<style scoped>
@media screen and (max-width: 1130px) {
  .top-bar-1{
    display:block;
  }
  .top-bar-2{
    padding-left:22.5px;
  }
}
@media screen and (min-width:1130px) {
  .top-bar-1{
    display:flex;
  }
  .top-bar-2{
    justify-content: flex-end;
    margin:0px 22.5px 0px 0;
  }
}
</style>
<style lang="scss" module>
  @import "./style.module.scss";
</style>
<style lang="scss">
  .addFloor-button.ant-btn.ant-btn-lg{
    background-color:#F7F5F9;
    color:var(--orange);
    border-color:var(--orange);
    &:hover{
      background-color:var(--orange);
      color:#fff;
    }
  }
</style>
<style lang="scss">
.unit-holder.select-block{
  border:none;
  -webkit-animation-name: setBorder;
  -webkit-animation-iteration-count: 2s;
  -webkit-animation-duration: 2s;
}
@-webkit-keyframes setBorder {
  100% { border:2px solid var(--orange);}
}
.ant-table-row.ant-table-row-level-0.select-list-table{
  -webkit-background-color:#FFF;
  -webkit-animation-name: setBackgroundColor;
  -webkit-animation-iteration-count: 2s;
  -webkit-animation-duration: 2s;
}
@-webkit-keyframes setBackgroundColor {
  100% { background-color: rgba(247,148,30,0.2); }
}

</style>
