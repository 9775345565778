<template>
	<div>
		<LeftNavBar :selectedSecondNav="currentUnitCategory" @trackNav="trackNav" :hasChild="true"
			:selectedValue="currentFloor" :title="selectedAppInitial" :items="$store.getters.sidebarItems"
			@opened="menuClicked" @optionclick="moreOptions" @chosen="childClicked" />
		<a-modal :visible="visible" @cancel="visible = !visible" :footer="null" :centered="true">
			<div>
				<h5>Rename</h5>
				<a-input v-model="newName" class="mt-3" :placeholder="floorName" />
				<div style="text-align: end">
					<a-button size="large" class="mt-5 cancel-button" @click="visible = !visible">CANCEL</a-button>
					<a-button size="large" type="primary" class="mt-5 ml-3" @click="confirmRename">CONFIRM</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
	components: { LeftNavBar },
	name: "left-sidebar",
	data() {
		return {
			visible: false,
			newName: "",
			renameObj: "",
			floorName: "",
		};
	},
	computed: {
		selectedAppInitial() {
			return (
				(this.$store.state.theApp &&
					this.$store.state.theApp.media &&
					this.$store.state.theApp.media.initial) ||
				"CUC"
			);
		},
		currentUnitCategory() {
			return this.$store.state.condoUnits.currentUnitCategory;
		},
		currentFloor() {
			let currentFloor = this.$store.state.condoUnits.currentFloor;
			if (
				currentFloor == "settings"
			) {
				return currentFloor;
			} else {
				let find = this.$store.state.condoUnits.floors.find(
					(x) => x.order == this.$store.state.condoUnits.currentFloor
				);
				if (find) return find.id;
				else return "0";
			}
		},
		instance() {
			return this.$store.state.instance;
		},
		units() {
			return this.$store.state.condoUnits.units;
		},
		unitsFiltered() {
			return this.$store.getters.unitsFiltered;
		},
		floors() {
			return this.$store.state.condoUnits.floors;
		},
	},
	methods: {
		trackNav(value) {
			this.$store.commit("UPDATE_NAV", value);
		},
		confirmRename() {
			this.$api
				.put(`unit-groups/${this.instance.id}/${this.renameObj}`, {
					name: this.newName,
				})
				.then(({ data }) => {
					this.$store.commit("RENAME_FLOOR", data);
					this.visible = false;
					this.newName = "";
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
		},
		childClicked(data) {
			this.$store.commit("SHOW_FLOOR", data.parent);
			this.$store.commit("SHOW_UNIT_PAGE", data.value);
			if (!this.$route.meta.showUnits) this.$router.push("/showunits");
		},
		moreOptions(data) {
			if (data.type == "child") {
				if (data.optionValue == "delete") {
					let deleteArray = [];
					for (var i in this.unitsFiltered) {
						if (i == data.itemValue) {
							for (var j in this.unitsFiltered[i]) {
								deleteArray.push(this.unitsFiltered[i][j]);
							}
						}
					}
					if (this.$p < 40)
						return this.$message.error(
							"You do not have permission to delete unit type"
						);
					let self = this;
					this.$confirm({
						title: "Delete",
						content: (h) => (
							<div>Do you want to delete this unit type?</div>
						),
						okText: "Delete",
						okType: "danger",
						cancelText: "Cancel",
						centered: true,
						async onOk() {
							for (var i = 0; i < deleteArray.length; i++) {
								let id = deleteArray[i].id;
								try {
									await self.$api.delete(
										`/units/${self.instance.id}/${deleteArray[i].id}`,
										{ buildingType: "condounits" }
									);
								} catch (err) {
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										self.$message.error(self.$err(err))
									}
								}
							}

							deleteArray.forEach(({ id }) => {
								self.$store.commit("SET_PROP", {
									where: ["units", id],
									del: true,
								});
							});
							// self.$store.commit('SET_CRUMBS', [{name:'All Units'}])
							self.$store.commit("SHOW_UNIT_PAGE", "all");
						},
						onCancel() {
						},
					});
				}
				if (data.optionValue == "unpublish") {
					let unpublishArray = [];
					for (var i in this.unitsFiltered) {
						if (i == data.itemValue) {
							for (var j in this.unitsFiltered[i]) {
								unpublishArray.push(this.unitsFiltered[i][j]);
							}
						}
					}
					if (this.$p < 40)
						return this.$message.error(
							"You do not have permission to unpublish unit "
						);

					let self = this;
					this.$confirm({
						title: "Unpublish",
						content: (h) => (
							<div>Do you want to unpublish this unit?</div>
						),
						okText: "Unpublish",
						okType: "danger",
						cancelText: "Cancel",
						centered: true,
						onOk() {
							for (var i = 0; i < unpublishArray.length; i++) {
								unpublishArray[i].status = "draft";
								let sendObj = JSON.parse(
									JSON.stringify(unpublishArray[i])
								);
								sendObj.instance = self.instance.id;
								sendObj.buildingType = "condounits";
								self.$api
									.put(
										`/units/${self.instance.id}/${sendObj.id}?type=condounits`,
										sendObj
									)
									.then(({ data }) => {
										("just updated");
									}).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									});
							}
							self.$message.success(
								"Successfully unpublished unit!"
							);
						},
						onCancel() {
						},
					});
				}
			}

			if (data.type == "parent") {
				if (data.optionValue == "rename") {
					for (var i in this.floors) {
						if (this.floors[i].id == data.itemValue) {
							this.floorName = this.floors[i].name;
						}
					}
					this.visible = true;
					for (var i in this.floors) {
						if (data.itemValue == this.floors[i].id) {
							this.renameObj = this.floors[i].id;
						}
					}
				}
				if (data.optionValue == "unpublish") {
					let unpublishArray = [];
					for (var i in this.units) {
						if (this.units[i].unitGroup.id == data.itemValue) {
							unpublishArray.push(this.units[i]);
						}
					}
					let self = this;

					if (this.$p < 40)
						return this.$message.error(
							"You do not have permission to unpublish floor"
						);

					this.$confirm({
						title: "Unpublish",
						content: (h) => (
							<div>Do you want to unpublish this floor?</div>
						),
						okText: "Unpublish",
						okType: "danger",
						cancelText: "Cancel",
						centered: true,
						onOk() {
							for (var i = 0; i < unpublishArray.length; i++) {
								unpublishArray[i].status = "draft";
								let sendObj = JSON.parse(
									JSON.stringify(unpublishArray[i])
								);
								sendObj.instance = self.instance.id;
								sendObj.buildingType = "condounits";
								self.$api
									.put(
										`/units/${self.instance.id}/${sendObj.id}?type=condounits`,
										sendObj
									)
									.then(({ data }) => {
									}).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									});
							}
							self.$message.success(
								"Successfully unpublished floor!"
							);
						},
						onCancel() {
						},
					});
				}
				if (data.optionValue == "delete") {
					this.$store.commit("DEL_FLOOR");
					let deleteArray = [];
					if (this.units && this.units.length) {
						for (var i in this.units) {
							if (this.units[i].unitGroup.id == data.itemValue) {
								deleteArray.push(this.units[i]);
							}
						}
					}
					if (this.$p < 20)
						return this.$message.error(
							"You do not have permission to delete floors"
						);
					let self = this;
					this.$confirm({
						title: "Delete Floor",
						content: (h) => (
							<div>Do you want to delete this floor?</div>
						),
						okText: "Delete",
						okType: "danger",
						cancelText: "Cancel",
						centered: true,
						async onOk() {
							if (deleteArray.length) {
								for (var i = 0; i < deleteArray.length; i++) {
									let id = deleteArray[i].id;
									try {
										await self.$api.delete(
											`/units/${self.instance.id}/${deleteArray[i].id}`,
											{ buildingType: "condounits" }
										);
									} catch (err) {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									}
								}
								deleteArray.forEach(({ id }) => {
									self.$store.commit("SET_PROP", {
										where: ["units", id],
										del: true,
									});
								});
								self.$api
									.delete(
										`/unit-groups/${self.instance.id}/${data.itemValue}`
									)
									.then(({ data }) => {
										self.$store.commit(
											"DELETE_FLOOR",
											data
										);
									}).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									});
							} else {
								self.$api
									.delete(
										`/unit-groups/${self.instance.id}/${data.itemValue}`
									)
									.then(({ data }) => {
										self.$store.commit(
											"DELETE_FLOOR",
											data
										);
									}).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									});
							}
						},
						onCancel() {
						},
					});
				}
			}
		},
		menuClicked(data) {
			this.$store.commit("SHOW_UNIT_PAGE", "all");
			this.$store.commit("SHOW_FLOOR", data.value);

			if (data.value == "settings") {
				if (!this.$route.path.includes("/settings")){
					this.$store.commit("SET_CRUMBS", [{ name: 'Settings' }]);
					this.$router.push("/settings");
				}
			} else {
				if (!this.$route.path.includes("/showunits")){
					this.$store.commit("SET_CRUMBS", [{ name: data.value }]);
					this.$router.push("/showunits");
				}
			}
		}
	},
	created() { },
};
</script>

<style lang="scss">
.uncategorized {
	background: var(--light-purple);
}
</style>
<style>
.cancel-button.ant-btn {
	border-color: #ece9f1 !important;
	background-color: #ece9f1;
	color: #3f3356;
}
</style>
